import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../components/v2/Layout';
import { Box, Grid, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import { navigate } from 'gatsby';
import Button from '../../components/v2/Button';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { getEmoriPoint, getEmoriRelationPersonalHealthPoint } from '../../apis/emori';
import MonthYearSelect from '../../components/MonthYearSelect';
import { getEmoriList } from '../../actions/emori';

const useStyles = makeStyles((theme) => ({
  paper3: {
    width: '100%',
    height: '100%',
    display: 'inline-block',
    backgroundColor: 'white',
    borderRadius: '0px 10px 10px 10px',
    padding: '0 10px 0 11px',
  },
  paper: {
    padding: '0 10px 0 11px',
  },
  paper2: {
    background: 'linear-gradient(91deg, rgba(255, 255, 255, 0.77) 0%, rgba(170, 204, 255, 0.79) 100%)',
    borderRadius: '15px',
    display: 'flex',
    padding: '5px 8px 5px 5px',
    alignItems: 'flex-end',
    margin: '20px 0 10px 0',
    flexDirection: 'column-reverse',
  },
  space: {
    display: 'flex',
    justifyContent: 'center',
  },
  tableCell: {
    border: 0,
    padding: '3px',
  },
  topicText1: {
    marginBottom: '3px',
    color: '#858484',
    fontSize: '14px',
    marginTop: '2px',
  },
  topicText2: {
    margin: 0,
    color: '#858484',
    fontSize: '14px',
  },
  percentage: {
    fontSize: '25px',
    fontWeight: 'bold',
    color: '#858484',
  },
  feelingText: {
    fontSize: '14px',
    color: '#858484',
  },
  emori: {
    width: '70%',
  },
  noFeeling: {
    width: '50%',
    margin: '10px',
  },
}))

export default ({ relation=false , partner=false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { month, year } = useSelector(state => state.emori);
  const [ date, setDate ] = React.useState('');
  const [ point, setPoint] = React.useState(null);
  const [ image, setImage ] = React.useState('');
  const [ context, setContext ] = React.useState('');
  const [ feeling, setFeeling ] = React.useState('');
  const [ percentage, setPercentage ] = React.useState('');
  const [ currentMonth, setCurrentMonth] = React.useState(new Date().getMonth() + 1);

  React.useEffect(() => {
    emoriList();
  }, [month, year]);

  const emoriList = async () => {
    try {
      dispatch(getEmoriList());
      let res = {};
      if (relation) {
        res = await getEmoriRelationPersonalHealthPoint(relation.room, relation.profile);
      } else {
        res = await getEmoriPoint(month, year);
      }
      setPoint(res[Object.keys(res)]);
      checkGroup(res[Object.keys(res)]);
    } catch (e) {}
  }

  const checkGroup = (emori) => {
    let text;
    if (emori.feeling == null && (currentMonth !== month)) {
      text = <p style={{ fontSize: '11px', color: '#827D7D' }}>ไม่มีรายงานภาพรวมของเดือนนี้ เนื่องจากคุณไม่ได้บันทึกอารมณ์ของคุณ</p>
      setImage(require(`../../assets/images/no_feeling.svg`));
    } else if (emori.feeling == null) {
      text = <p style={{ fontSize: '11px', color: '#827D7D' }}>กรุณาบันทึกอารมณ์ของคุณ เพื่อรับรายงานภาพรวมอารมณ์ของคุณประจำเดือนนี้</p>
      setImage(require(`../../assets/images/no_feeling.svg`));
    } else if (emori.feeling === 'good') {
      text = <p style={{ fontSize: '11px', color: '#827D7D' }}>เดือนนี้, อารมณ์ของคุณอยู่ในเกณฑ์ <span style={{ color: 'green', fontWeight: 'bold'}}>ดี</span> ขอให้วันนี้เป็นวันที่ดีของคุณ หากต้องการความช่วยเหลือในด้านสุขภาพจิต คุณสามารถปรึกษาจิตแพทย์เพื่อดูแลสุขภาพจิตของคุณได้ทันที</p>
      setFeeling('ดี');
      setImage(require(`../../assets/images/good.svg`));
    } else if (emori.feeling === 'bad') {
      text = <p style={{ fontSize: '11px', color: '#827D7D' }}>เดือนนี้, อารมณ์ของคุณ <span style={{ color: 'red', fontWeight: 'bold'}}>ไม่ดี</span> เท่าที่ควรขอให้วันนี้เป็นวันที่ดีขึ้น หากต้องการความช่วยเหลือในด้านสุขภาพจิต, คุณสามารถปรึกษาจิตแพทย์ได้ทันที</p>
      setFeeling('แย่');
      setImage(require(`../../assets/images/bad.svg`));
    } else {
      text = <p style={{ fontSize: '11px', color: '#827D7D' }}>เดือนนี้, อารมณ์ของคุณอยู่ในเกณฑ์ <span style={{ color: 'green', fontWeight: 'bold'}}>ปานกลาง</span> ขอให้เป็นวันที่ดีขึ้นนะ หากต้องการความช่วยเหลือในด้านสุขภาพจิต คุณสามารถปรึกษาจิตแพทย์ได้ทันที</p>
      setImage(require(`../../assets/images/normal.svg`));
      setFeeling('ปานกลาง');
    }
    setPercentage(emori.percentage);
    setContext(text);
  }

  const onClickNofeeling = () => {
    if (partner) {
      navigate(`/partners/emori/?partnerId=${partner}&tab=0`, { replace: true });
    } else if (relation) {
      navigate(`/emori/relation/chooseEmori?profileId=${relation.profile}&tab=0`, { replace: true });
    } else {
      navigate(`/emori?tab=0`, { replace: true });
    }
  }

  const onClickConsult = () => {
    if (partner) {
      navigate(`/partners/consult/chooseCategory?partnerId=${partner}`, { replace: true });
    } else {
      navigate(`/consult/patientApp/chooseCategory`, { replace: true });
    }
  }

  return (
    <div>
      <div className={classes.paper}>
        <MonthYearSelect />
        {
          point && !point.feeling ?
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '15px' }}>
              <img src={require(`../../assets/images/jar-empty.svg`)} alt='empty'/>
            </div>
            :
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '15px' }} onClick={() => navigate(`/emori/summaryMonth/?member=${relation.profile}&room=${relation.room}`)}>
              <img src={require(`../../assets/images/jar-monthly.svg`)} alt='monthly'/>
            </div>
        }
        {
          point && !point.feeling ?
            <div className={classes.paper2} onClick={(currentMonth !== month) ? null : () => onClickNofeeling()}>
              <Grid container spacing={1}>
                <Grid item xs={6} className={classes.space}>
                  <img className={classes.noFeeling} src={image} alt='no_feeling' />
                </Grid>
                <Grid item xs={6} style={{ display: 'flex' }}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell align='center' className={classes.tableCell}>
                          <p className={classes.topicText1}>คุณ{(currentMonth !== month) ? '' : 'ยัง'}ไม่ได้บันทึก</p>
                          <p className={classes.topicText2}>ความรู้สึกของคุณ{(currentMonth !== month) ? '' : 'วันนี้'}</p>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </div>
          :
          <div className={classes.paper2} onClick={() => navigate(`/emori/graphEmori/?member=${relation.profile}&room=${relation.room}`)}>
            <Grid container spacing={1}>
              <Grid item xs={6} className={classes.space}>
                <img className={classes.emori} src={image} alt='feeling_img' />
              </Grid>
              <Grid item xs={6}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell align='center' className={classes.tableCell}>
                        <p className={classes.topicText1}>อารมณ์เฉลี่ย</p>
                        <p className={classes.topicText2}>ของคุณในเดือนนี้</p>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='center' className={classes.tableCell}>
                        <Typography className={classes.percentage}>{percentage}%</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='center' className={classes.tableCell}>
                        <Typography className={classes.feelingText}>{feeling}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            <span style={{ fontSize: '9px', color: '#878181' }}>view more</span>
          </div>
        }
        {context}
        {
          !relation && (
          <Box height='75px' display='flex' justifyContent='center' alignItems='center'>
            <Box flex={1}>
              <Button fullWidth size='large' onClick={() => navigate('/menu/mental')}>ประเมินสุขภาพจิต</Button>
            </Box>
            <Box flex={1} marginLeft={1}>
              <Button fullWidth size='large' onClick={() => onClickConsult()}>ปรึกษาแพทย์</Button>
            </Box>
          </Box>
        )}
      </div>
    </div>
  )
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  btn: {
    borderRadius: 20,
    fontWeight: 800,
    fontSize: '1.05em'
  },
}));


export default (props) => {
  const classes = useStyles();

  return (
    <Button className={classes.btn} variant="contained" color="primary" {...props}/>
  )
}